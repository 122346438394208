import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const CustomLink = styled(Link)`
  padding: ${(props) => props.padding}px 0;
  cursor: pointer;
  display: block;
  text-decoration: none;
`;

const Line = styled.div`
  position: absolute;
  left: 0;
  bottom: ${(props) => (props.size / 0.4667)}px;
  height: ${(props) => (props.size / 0.7)}px;
  width: 0;
  background-color: ${(props) => props.color};
  transition: width 0.2s;
  user-select: none;
  visibility: hidden;
`;

const Pencil = styled.i`
  position: absolute;
  right: calc(-0.9em + 2px);
  bottom: -3px;
`;

const Text = styled.span`
  position: relative;
  color: ${(props) => props.color};
  font-size: ${(props) => props.size}em;

  &:hover ${Line} {
    width: calc(100% + 0px);
    visibility: visible
  }
`;

export default (props) => {
  const {
    children, color, size, padding,
  } = props;

  return (
    <CustomLink {...props} padding={padding}>
      <Text color={color} size={size}>
        {children}
        <Line color={color} size={size}>
          {/* <Pencil className="material-icons">edit</Pencil> */}
        </Line>
      </Text>
    </CustomLink>
  );
};
